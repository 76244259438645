import { useQuery } from "@tanstack/react-query";

import { COVER_PREVIEW_KEY } from "@/client/constants/query-keys";
import { axios } from "@/client/libs/axios";

export const previewCover = async (data: { id: string }) => {
  const response = await axios.get(`/cover/print/${data.id}/preview`);

  return response.data;
};

export const useCoverPreview = (id: string) => {
  const {
    error,
    isPending: loading,
    data,
  } = useQuery({
    queryKey: [COVER_PREVIEW_KEY, { id }],
    queryFn: () => previewCover({ id }),
  });

  return { url: data?.url, loading, error };
};
