/* eslint-disable lingui/no-unlocalized-strings */
import { useBreakpoint } from "@resume-plus/hooks";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Sheet,
  SheetContent,
} from "@resume-plus/ui";
import { cn } from "@resume-plus/utils";
import { CircleUserRound } from "lucide-react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useBuilderStore } from "@/client/stores/builder";
import { useResumeStore } from "@/client/stores/resume";

import { BuilderNavigationMenu } from "../_components/header";
import { BuilderToolbar } from "./_components/toolbar";
import { LeftSidebar } from "./sidebars/left";
import ProjectInfo from "./sidebars/left/project-info";
import { RightSidebar } from "./sidebars/right";

const onOpenAutoFocus = (event: Event) => {
  event.preventDefault();
};

const OutletSlot = () => {
  return (
    <>
      {/* <BuilderHeader /> */}

      <div className="absolute inset-0">
        <Outlet />
      </div>

      <BuilderToolbar />
    </>
  );
};

export const ResumeBuilderLayout = () => {
  const { isDesktop } = useBreakpoint();

  const sheet = useBuilderStore((state) => state.sheet);
  const title = useResumeStore((state) => state.document.title);
  const setValue = useResumeStore((state) => state.setValue);
  const setBuilderType = useBuilderStore((state) => state.setBuilderType);

  useEffect(() => {
    setBuilderType("resume");
  }, []);

  const [activeTab, setActiveTab] = useState("fill-in");

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const onTitleUpdate = (value: string) => {
    if (value === title) return;
    setValue("title", value);
  };

  if (isDesktop) {
    return (
      <div className="flex h-screen w-full flex-col">
        <header className="z-[200] flex h-16 gap-4 border-b bg-background px-4 md:px-6">
          <BuilderNavigationMenu />
          <div className="flex w-full items-center justify-end gap-4 md:ml-auto md:gap-2 lg:gap-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="secondary" size="icon" className="rounded-full">
                  <CircleUserRound className="size-5" />
                  <span className="sr-only">Toggle user menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Settings</DropdownMenuItem>
                <DropdownMenuItem>Support</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Logout</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>

        <div className="flex h-full flex-row overflow-hidden">
          <div className="flex h-full min-w-[560px] max-w-[760px] flex-[0_0_40%] flex-col overflow-hidden">
            <div className="relative z-[100] h-12 flex-none">
              <ProjectInfo
                projectName={title}
                activeTab={activeTab}
                onTabChange={handleTabChange}
                onProjectNameChange={onTitleUpdate}
              />
            </div>
            <div className={cn("z-10 flex-1 overflow-y-auto bg-background")} id="left-sidebar">
              {activeTab === "fill-in" && <LeftSidebar />}
              {activeTab === "layout" && <RightSidebar />}
            </div>
          </div>
          <div className="relative flex-1">
            <OutletSlot />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed w-full flex-col">
      <header className="z-[100] flex h-16 gap-4 border-b bg-background px-4 md:px-6">
        <BuilderNavigationMenu />
      </header>
      <div className="z-[100]">
        <ProjectInfo
          projectName={title}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          onProjectNameChange={onTitleUpdate}
        />
      </div>

      <Sheet open={sheet.right.open} onOpenChange={sheet.right.setOpen}>
        <SheetContent side="right" className="p-0" onOpenAutoFocus={onOpenAutoFocus}>
          <OutletSlot />
        </SheetContent>
      </Sheet>
      <LeftSidebar />
      <Sheet open={sheet.bottom.open} onOpenChange={sheet.bottom.setOpen}>
        <SheetContent side="bottom" className="h-1/2 p-0" onOpenAutoFocus={onOpenAutoFocus}>
          <RightSidebar />
        </SheetContent>
      </Sheet>
    </div>
  );
};
