/* eslint-disable unicorn/no-nested-ternary */
/* eslint-disable lingui/no-unlocalized-strings */
// ScoreCard.tsx
import { Button, Card, CardContent, Progress } from "@resume-plus/ui";
import React from "react";

type ATSParseRateProps = {
  description: string;
  parseRate: number;
};

export const ATSParseRate: React.FC<ATSParseRateProps> = ({ description, parseRate }) => {
  return (
    <div>
      <h3 className="mb-2 text-lg font-semibold">ATS PARSE RATE</h3>
      <p className="mb-4">{description}</p>
      <Progress value={parseRate} className="mb-4" />
      <div className="mb-4 text-center">
        <p className="font-bold">Great!</p>
        <p>We parsed {parseRate}% of your resume successfully using an industry-leading ATS.</p>
      </div>
      <div className="rounded-lg bg-purple-100 p-4 text-center">
        <p className="mb-2">Job-Winning Resume In Minutes</p>
        <Button variant="secondary">Create an Enhancv Resume</Button>
      </div>
    </div>
  );
};

type ContentSectionProps = {
  content: {
    title: string;
    description: string;
    parseRate: number;
  };
};

export const ContentSection: React.FC<ContentSectionProps> = ({ content }) => {
  return (
    <Card className="w-2/3 shadow-lg">
      <CardContent className="p-6">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-xl font-semibold">{content.title}</h2>
          <span className="rounded-full border bg-white px-3 py-1 text-sm text-gray-700">
            14 ISSUES FOUND
          </span>
        </div>
        <h3 className="mb-2 text-lg font-semibold">ATS PARSE RATE</h3>
        <p className="mb-4 text-sm text-gray-600">{content.description}</p>
        <Progress value={content.parseRate} className="mb-4 h-2" />
        <div className="mb-6 text-center">
          <p className="font-bold">Great!</p>
          <p className="text-sm">
            We parsed {content.parseRate}% of your resume successfully using an industry-leading
            ATS.
          </p>
        </div>
        <div className="rounded-lg bg-indigo-50 p-4 text-center">
          <p className="mb-2 font-semibold">Job-Winning Resume In Minutes</p>
          <Button variant="secondary" className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200">
            Create an Enhancv Resume
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

type ScoreCardProps = {
  score: number;
  totalIssues: number;
  categories: {
    name: string;
    score: number;
    items: string[];
  }[];
};

export const ScoreCard: React.FC<ScoreCardProps> = ({ score, totalIssues, categories }) => {
  return (
    <Card className="w-1/3 shadow-lg">
      <CardContent className="p-6">
        <h2 className="mb-2 text-xl font-semibold">Your Score</h2>
        <div className="text-4xl font-bold text-red-500">{score}/100</div>
        <div className="mb-6 text-sm text-gray-500">{totalIssues} Issues</div>
        <div className="space-y-3">
          {categories.map((category, index) => (
            <div key={index} className="flex items-center justify-between">
              <span className="text-sm font-medium">{category.name}</span>
              <span
                className={`rounded-full px-2 py-1 text-xs ${
                  category.score >= 67
                    ? "bg-green-100 text-green-800"
                    : category.score >= 50
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-red-100 text-red-800"
                }`}
              >
                {category.score}%
              </span>
            </div>
          ))}
        </div>
        <Button className="mt-6 w-full bg-indigo-600 text-white hover:bg-indigo-700">
          Unlock Full Report
        </Button>
      </CardContent>
    </Card>
  );
};

type MainContentProps = {
  score: number;
  totalIssues: number;
  categories: {
    name: string;
    score: number;
    items: string[];
  }[];
  content: {
    title: string;
    description: string;
    parseRate: number;
  };
};

export const MainContent: React.FC<MainContentProps> = ({
  score,
  totalIssues,
  categories,
  content,
}) => {
  return (
    <div className="flex min-h-screen gap-6 bg-gray-100 p-6">
      <ScoreCard score={score} totalIssues={totalIssues} categories={categories} />
      <ContentSection content={content} />
    </div>
  );
};

export const RCV1: React.FC = () => {
  const contentData = {
    score: 48,
    totalIssues: 23,
    categories: [
      {
        name: "CONTENT",
        score: 25,
        items: ["ATS Parse Rate", "Quantifying Impact", "Repetition", "Spelling & Grammar"],
      },
      { name: "FORMAT", score: 67, items: [] },
      { name: "SECTIONS", score: 0, items: [] },
      { name: "SKILLS", score: 100, items: [] },
      { name: "STYLE", score: 50, items: [] },
    ],
    content: {
      title: "CONTENT",
      description:
        "An Applicant Tracking System commonly referred to as ATS is a system used by employers and recruiters to quickly scan a large number of job applications. A high parse rate of your resume ensures that the ATS can read your resume, experience, and skills. This increases the chance of getting your resume seen by recruiters.",
      parseRate: 91,
    },
  };

  return (
    <div className="container mx-auto min-h-screen">
      <MainContent {...contentData} />
    </div>
  );
};
