/* eslint-disable lingui/no-unlocalized-strings */
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
} from "@resume-plus/ui";
import { templatesList } from "@resume-plus/utils";
import { motion } from "framer-motion";

import { useCoverLetterStore } from "@/client/stores/resume";

export const ThemeDialog = () => {
  const setValue = useCoverLetterStore((state) => state.setValue);
  const currentTemplate = useCoverLetterStore((state) => state.document.data.metadata.template);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="hidden lg:inline-flex">
          View All Templates
        </Button>
      </DialogTrigger>
      <DialogContent className="z-[200] max-h-[80vh] max-w-[60vw] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>All Templates</DialogTitle>
        </DialogHeader>
        <ScrollArea>
          <div className="grid grid-cols-3 gap-4 p-4">
            {templatesList.map((template, index) => (
              <div className="m-2">
                <div className="relative mt-10">
                  <div className="absolute inset-x-0 -bottom-1 h-12 w-full">
                    <p className="absolute inset-x-0 bottom-3 text-center text-sm font-bold capitalize text-info">
                      {template}
                    </p>
                  </div>
                </div>
                <motion.div
                  key={template}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: index * 0.05 } }}
                  whileTap={{ scale: 0.95 }}
                  className={`relative cursor-pointer rounded-sm ring-2 ring-secondary ${
                    currentTemplate === template ? "!ring-primary" : ""
                  }`}
                  onClick={() => {
                    setValue("metadata.template", template);
                  }}
                >
                  <img
                    src={`/templates/cover/jpg/${template}.jpg`}
                    alt={template}
                    className="w-full rounded-sm object-cover"
                  />
                </motion.div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
