/* eslint-disable lingui/no-unlocalized-strings */
import { Trash, UploadSimple } from "@phosphor-icons/react";
import { Avatar, AvatarFallback, AvatarImage } from "@resume-plus/ui";
import { useCallback, useMemo, useRef, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { z } from "zod";

import {
  FileWithPreview,
  ImageUploadDialog,
} from "@/client/pages/builder/_components/image-upload-dialog";
import { useUploadImage } from "@/client/services/storage";
import { useResumeStore } from "@/client/stores/resume";

const accept = {
  "image/*": [],
};

export const PictureSection = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadImage } = useUploadImage();

  const setValue = useResumeStore((state) => state.setValue);
  const picture = useResumeStore((state) => state.document.data.basics.picture);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileWithPreview | null>(null);

  const isValidUrl = useMemo(() => z.string().url().safeParse(picture.url).success, [picture.url]);

  const onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const response = await uploadImage(file);
      const url = response.data;

      setValue("basics.picture.url", url);
    }
  };

  const onAvatarClick = () => {
    if (isValidUrl) {
      setValue("basics.picture.url", "");
    } else {
      inputRef.current?.click();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      const file = acceptedFiles[0];
      const fileWithPreview = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      setSelectedFile(fileWithPreview);
      setDialogOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div className="flex items-center gap-x-4">
      <div className="group relative cursor-pointer" onClick={onAvatarClick}>
        {selectedFile ? (
          <ImageUploadDialog
            dialogOpen={isDialogOpen}
            setDialogOpen={setDialogOpen}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        ) : (
          <Avatar
            {...getRootProps()}
            className="size-36 cursor-pointer ring-2 ring-slate-200 ring-offset-2"
          >
            <input {...getInputProps()} />
            <AvatarImage src={picture.url} alt="profile-picture" />
            <AvatarFallback>
              <div className="flex gap-x-2">
                <UploadSimple className="mt-1" />
                <p>Upload Photo</p>
              </div>
            </AvatarFallback>
          </Avatar>
        )}

        {isValidUrl && (
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center rounded-full bg-background/30 opacity-0 transition-opacity group-hover:opacity-100">
            <Trash size={16} weight="bold" />
          </div>
        )}
      </div>

      <div className="flex w-full flex-col gap-y-1.5">
        <div className="flex items-center gap-x-2">
          <input ref={inputRef} hidden type="file" onChange={onSelectImage} />

          {/* {isValidUrl && (
            <Popover>
              <PopoverTrigger asChild>
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={cn(buttonVariants({ size: "icon", variant: "ghost" }))}
                >
                  <Aperture />
                </motion.button>
              </PopoverTrigger>
              <PopoverContent className="w-[360px]">
                <PictureOptions />
              </PopoverContent>
            </Popover>
          )} */}
        </div>
      </div>
    </div>
  );
};
