import { t } from "@lingui/macro";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@resume-plus/ui";
import { templatesList } from "@resume-plus/utils";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { motion } from "framer-motion";
import React, { useRef } from "react";

import { useResumeStore } from "@/client/stores/resume";

import { getSectionIcon } from "../shared/section-icon";
import { ThemeDialog } from "./theme-dialog";

export const TemplateSection = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const currentTemplate = useResumeStore((state) => state.document.data.metadata.template);
  const carouselRef = useRef(null);
  const [api, setApi] = React.useState<CarouselApi>();
  // eslint-disable-next-line unicorn/prefer-array-index-of
  const currentIndex = templatesList.findIndex((i) => i === currentTemplate);

  React.useEffect(() => {
    api?.scrollTo(currentIndex);
  }, [api, currentIndex]);

  return (
    <section id="template" className="space-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("template")}
          <h2 className="line-clamp-1 font-inter text-xl font-semibold">{t`Template`}</h2>
        </div>
        <ThemeDialog />
      </header>

      <Carousel
        ref={carouselRef}
        setApi={setApi}
        opts={{
          dragFree: true,
        }}
        className="w-full"
        plugins={[WheelGesturesPlugin()]}
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          {templatesList.map((template, index) => (
            <CarouselItem key={template} className="basis-2/5 pl-2 md:pl-4 lg:basis-[45%]">
              <div className="m-2">
                <div className="relative mt-10">
                  <div className="absolute inset-x-0 -bottom-1 h-12 w-full">
                    <p className="absolute inset-x-0 bottom-3 text-center text-sm font-bold capitalize text-info">
                      {template}
                    </p>
                  </div>
                </div>
                <motion.div
                  key={template}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: index * 0.05 } }}
                  whileTap={{ scale: 0.95 }}
                  className={`relative cursor-pointer rounded-sm ring-2 ring-secondary ${
                    currentTemplate === template ? "!ring-primary" : ""
                  }`}
                  onClick={() => {
                    setValue("metadata.template", template);
                  }}
                >
                  <img
                    src={`/templates/jpg/${template}.jpg`}
                    alt={template}
                    className="w-full rounded-sm object-cover"
                  />
                </motion.div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="hidden lg:block">
          <CarouselPrevious variant="primary" className="left-0" />
          <CarouselNext variant="primary" className="right-0" />
        </div>
      </Carousel>
    </section>
  );
};
