/* eslint-disable lingui/no-unlocalized-strings */
import { Check, Eye, FileText, Layout, PencilSimple, TextT, X } from "@phosphor-icons/react";
import { Button, Input, Tabs, TabsList, TabsTrigger } from "@resume-plus/ui";
import type React from "react";
import { KeyboardEvent, MouseEvent, useRef, useState } from "react";

import { useBuilderStore } from "@/client/stores/builder";

type ProjectInfoProps = {
  projectName: string;
  activeTab: string;
  onTabChange: (value: string) => void;
  onProjectNameChange: (newName: string) => void;
};

const ProjectInfo: React.FC<ProjectInfoProps> = ({
  projectName,
  activeTab,
  onTabChange,
  onProjectNameChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(projectName);
  const inputRef = useRef<HTMLInputElement>(null);
  const toggle = useBuilderStore((state) => state.toggle);

  const onToggle = (side: "left" | "right") => {
    toggle(side);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleSaveClick = () => {
    onProjectNameChange(editedName);
    setIsEditing(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveClick();
    } else if (e.key === "Escape") {
      revert();
    }
  };

  const revert = () => {
    onProjectNameChange(projectName);
    setIsEditing(false);
  };

  const handleDoubleClick = (_: MouseEvent<HTMLSpanElement>) => {
    handleEditClick();
  };

  return (
    <div className="flex h-full items-center justify-between border-b bg-background p-4 lg:pl-4 lg:pr-0">
      <div className="flex items-center space-x-2">
        <FileText className="size-6 text-primary" weight="bold" />
        {isEditing ? (
          <Input
            ref={inputRef}
            type="text"
            defaultValue={projectName}
            className="line-clamp-1 border-b border-border bg-transparent text-sm font-bold focus:border-primary focus:outline-none"
            onChange={(e) => {
              setEditedName(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            // onBlur={handleSaveClick}
          />
        ) : (
          <span
            className="line-clamp-1 cursor-text text-sm font-bold"
            onDoubleClick={handleDoubleClick}
          >
            {projectName}
          </span>
        )}
        {isEditing ? (
          <>
            <Check
              className="size-4 cursor-pointer text-green-500"
              weight="bold"
              onClick={handleSaveClick}
            />
            <X className="size-4 cursor-pointer text-red-500" weight="bold" onClick={revert} />
          </>
        ) : (
          <PencilSimple
            className="size-4 cursor-pointer text-gray-400"
            weight="bold"
            onClick={handleEditClick}
          />
        )}
      </div>
      <div className="items-center space-x-2">
        <Tabs
          defaultValue={activeTab}
          className="mr-4 hidden w-[300px] lg:block"
          onValueChange={onTabChange}
        >
          <TabsList className="z-10 grid grid-cols-2">
            <TabsTrigger value="fill-in">
              <TextT className="size-4" weight="bold" />
              <span>Edit</span>
            </TabsTrigger>
            <TabsTrigger value="layout">
              <Layout className="size-4" weight="bold" />
              <span>Design</span>
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <Button
          variant="primary"
          className="rounded lg:hidden"
          onClick={() => {
            onToggle("right");
          }}
        >
          <Eye className="mr-2 size-6 cursor-pointer" weight="bold" />
          <h2 className="line-clamp-1 text-sm font-bold">Preview</h2>
        </Button>
      </div>
    </div>
  );
};

export default ProjectInfo;
