import { t } from "@lingui/macro";
import { ScrollArea, Separator } from "@resume-plus/ui";
import { useRef } from "react";

import { ThemeSwitch } from "@/client/components/theme-switch";

import { LayoutSection } from "./sections/layout";
import { NotesSection } from "./sections/notes";
import { PageSection } from "./sections/page";
import { SharingSection } from "./sections/sharing";
import { TemplateSection } from "./sections/template";
import { ThemeSection } from "./sections/theme";
import { TypographySection } from "./sections/typography";
import { SectionIcon } from "./shared/section-icon";

export const RightSidebar = () => {
  const containterRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = (selector: string) => {
    const section = containterRef.current?.querySelector(selector);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex bg-secondary-accent/30">
      <div className="hidden basis-12 flex-col items-center justify-center gap-y-4 bg-secondary-accent/30 py-4 sm:flex">
        <SectionIcon
          id="template"
          name={t`Template`}
          onClick={() => {
            scrollIntoView("#template");
          }}
        />
        <SectionIcon
          id="layout"
          name={t`Layout`}
          onClick={() => {
            scrollIntoView("#layout");
          }}
        />
        <SectionIcon
          id="typography"
          name={t`Typography`}
          onClick={() => {
            scrollIntoView("#typography");
          }}
        />
        <SectionIcon
          id="theme"
          name={t`Theme`}
          onClick={() => {
            scrollIntoView("#theme");
          }}
        />
        <SectionIcon
          id="page"
          name={t`Page`}
          onClick={() => {
            scrollIntoView("#page");
          }}
        />
        <SectionIcon
          id="sharing"
          name={t`Sharing`}
          onClick={() => {
            scrollIntoView("#sharing");
          }}
        />
        {/* <SectionIcon
            id="statistics"
            name={t`Statistics`}
            onClick={() => {
              scrollIntoView("#statistics");
            }}
          /> */}
        {/* <SectionIcon
            id="export"
            name={t`Export`}
            onClick={() => {
              scrollIntoView("#export");
            }}
          /> */}
        <SectionIcon
          id="notes"
          name={t`Notes`}
          onClick={() => {
            scrollIntoView("#notes");
          }}
        />

        <ThemeSwitch size={14} />
      </div>

      <ScrollArea orientation="vertical" className="h-[calc(100vh-110px)] flex-1 pb-16 lg:pb-0">
        <div ref={containterRef} className="grid gap-y-6 p-6 @container/right">
          <TemplateSection />
          <Separator />
          <LayoutSection />
          <Separator />
          <TypographySection />
          <Separator />
          <ThemeSection />
          <Separator />
          <PageSection />
          <Separator />
          <SharingSection />
          {/* <Separator /> */}
          {/* <StatisticsSection /> */}
          {/* <Separator /> */}
          {/* <ExportSection /> */}
          <Separator />
          <NotesSection />
          {/* <Separator /> */}
          {/* <InformationSection /> */}
          {/* <Separator /> */}
          {/* <Copyright className="text-center" /> */}
        </div>
      </ScrollArea>
    </div>
  );
};
