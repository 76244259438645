import { t } from "@lingui/macro";
import { ResumeDto } from "@resume-plus/dto";
import { useBreakpoint } from "@resume-plus/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LoaderFunction, redirect } from "react-router-dom";

import { queryClient } from "@/client/libs/query-client";
import { findResumeById } from "@/client/services/resume";
import { useBuilderStore } from "@/client/stores/builder";
import { useResumeStore } from "@/client/stores/resume";

import ResumeReloadPrompt from "./_components/reload-resume";

export const ResumeBuilderPage = () => {
  const { isDesktop } = useBreakpoint();
  const frameRef = useBuilderStore((state) => state.frame.ref);
  const setFrameRef = useBuilderStore((state) => state.frame.setRef);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const loadAttempts = useRef(0);
  const maxLoadAttempts = 3;

  const resume = useResumeStore((state) => state.document);
  const title = useResumeStore((state) => state.document.title);

  const updateResumeInFrame = useCallback(() => {
    if (!frameRef?.contentWindow) return;
    const message = {
      type: "SET_RESUME",
      payload: {
        document: resume.data,
        isDesktop,
        builderLayout: isDesktop ? "classic" : "modern",
        builderType: "resume",
      },
    };
    frameRef.contentWindow.postMessage(message, "*");
  }, [frameRef, resume.data, isDesktop]);

  const reloadFrame = useCallback(() => {
    if (frameRef && loadAttempts.current < maxLoadAttempts) {
      loadAttempts.current += 1;
      console.log(`trying again to load frame: ${loadAttempts.current}`);
      frameRef.src = `/artboard/builder?reload=${Date.now()}`;
    } else if (loadAttempts.current >= maxLoadAttempts) {
      setShowPrompt(true);
    }
  }, [frameRef]);

  useEffect(() => {
    if (!frameRef) return;

    const handleLoad = () => {
      updateResumeInFrame();
      setFrameLoaded(true);
    };

    frameRef.addEventListener("load", handleLoad);
    return () => {
      frameRef.removeEventListener("load", handleLoad);
    };
  }, [frameRef, updateResumeInFrame]);

  useEffect(() => {
    if (frameLoaded) {
      updateResumeInFrame();
    }
  }, [frameLoaded, updateResumeInFrame]);

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.data.type === "RESUME_UPDATE_RECEIVED") {
      console.log(`Message ${event.data.id} received by iframe`);
      setFrameLoaded(true);
      loadAttempts.current = 0;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!frameLoaded) {
        reloadFrame();
      }
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [frameLoaded, reloadFrame]);

  return (
    <>
      <Helmet>
        <title>
          {title} - {t`Resume Plus`}
        </title>
      </Helmet>

      {showPrompt && !frameLoaded && (
        <ResumeReloadPrompt
          onReload={() => {
            loadAttempts.current = 0;
            reloadFrame();
            setShowPrompt(false);
          }}
        />
      )}

      <iframe ref={setFrameRef} title={resume.id} src="/artboard/builder" className="size-full" />
    </>
  );
};

export const builderLoader: LoaderFunction<ResumeDto> = async ({ params }) => {
  try {
    const id = params.id!;
    const resume = await queryClient.fetchQuery({
      queryKey: ["resume", { id }],
      queryFn: () => findResumeById({ id }),
    });
    useResumeStore.setState({ document: resume });
    useResumeStore.temporal.getState().clear();
    return resume;
  } catch {
    return redirect("/dashboard");
  }
};
