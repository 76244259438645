/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  CircleNotch,
  ClockClockwise,
  CubeFocus,
  FilePdf,
  Hash,
  Layout,
  LineSegment,
  LinkSimple,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from "@phosphor-icons/react";
import {
  Button,
  Separator,
  Toggle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@resume-plus/ui";
import { motion } from "framer-motion";
import type React from "react";
import type { ReactNode } from "react";

type ToolbarButtonProps = {
  onClick: () => void;
  icon: ReactNode;
  tooltip: string;
  disabled?: boolean;
  side: "top" | "right" | "bottom" | "left";
  text?: string;
  size: "sm" | "md" | "lg" | "icon";
};

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  onClick,
  icon,
  tooltip,
  disabled = false,
  side = "left",
  text,
  size = "icon",
}) => (
  <Tooltip>
    <TooltipTrigger>
      <Button
        size={size}
        variant={text ? "primary" : "ghost"}
        className="rounded-full"
        disabled={disabled}
        onClick={() => onClick()}
      >
        {icon} {text && <p className="line-clamp-1 px-1 text-sm font-bold">{text}</p>}
      </Button>
    </TooltipTrigger>
    <TooltipContent side={side}>
      <p>{tooltip}</p>
    </TooltipContent>
  </Tooltip>
);

type ToolbarToggleProps = {
  pressed: boolean;
  onPressedChange: (pressed: boolean) => void;
  icon: ReactNode;
  tooltip: string;
};

const ToolbarToggle: React.FC<ToolbarToggleProps> = ({
  pressed,
  onPressedChange,
  icon,
  tooltip,
}) => (
  <Tooltip>
    <TooltipTrigger>
      <Toggle className="rounded-none" pressed={pressed} onPressedChange={onPressedChange}>
        {icon}
      </Toggle>
    </TooltipTrigger>
    <TooltipContent side="top">
      <p>{tooltip}</p>
    </TooltipContent>
  </Tooltip>
);

type ToolbarProps = {
  isMobile: boolean;
  undo: () => void;
  redo: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onResetView: () => void;
  onCenterView: () => void;
  pageOptions?: {
    breakLine?: boolean;
    pageNumbers?: boolean;
  };
  setValue: (path: string, value: boolean) => void;
  isPublic: boolean;
  onCopy: () => void;
  loading: boolean;
  onPrint: () => void;
  onLayout: () => void;
};

const Toolbar: React.FC<ToolbarProps> = ({
  isMobile,
  undo,
  redo,
  onZoomIn,
  onZoomOut,
  onResetView,
  onCenterView,
  pageOptions,
  setValue,
  isPublic,
  onCopy,
  loading,
  onPrint,
  onLayout,
}) => {
  const side = "top";
  const orientation = "vertical";
  const size = isMobile ? "sm" : "icon";

  const toolbarContent = (
    <>
      {isMobile && (
        <ToolbarButton
          size={size}
          text={t`Layout`}
          icon={<Layout />}
          tooltip={t`Layout`}
          disabled={loading}
          side={side}
          onClick={onLayout}
        />
      )}
      {!isMobile && (
        <>
          <ToolbarButton
            side={side}
            icon={<ArrowCounterClockwise />}
            tooltip={t`Undo`}
            size={size}
            onClick={undo}
          />
          <ToolbarButton
            size={size}
            side={side}
            icon={<ArrowClockwise />}
            tooltip={t`Redo`}
            onClick={redo}
          />
          <Separator orientation={orientation} />
        </>
      )}
      <ToolbarButton
        side={side}
        icon={<MagnifyingGlassPlus />}
        tooltip={t`Zoom In`}
        size={size}
        onClick={onZoomIn}
      />
      <ToolbarButton
        side={side}
        icon={<MagnifyingGlassMinus />}
        tooltip={t`Zoom Out`}
        size={size}
        onClick={onZoomOut}
      />
      <ToolbarButton
        side={side}
        icon={<ClockClockwise />}
        tooltip={t`Reset Zoom`}
        size={size}
        onClick={onResetView}
      />
      <ToolbarButton
        side={side}
        icon={<CubeFocus />}
        tooltip={t`Center Artboard`}
        size={size}
        onClick={onCenterView}
      />
      <Separator orientation={orientation} />
      {!isMobile && pageOptions && (
        <>
          {pageOptions.breakLine && (
            <ToolbarToggle
              pressed={pageOptions.breakLine}
              icon={<LineSegment />}
              tooltip={t`Toggle Page Break Line`}
              onPressedChange={(pressed) => setValue("metadata.page.options.breakLine", pressed)}
            />
          )}
          {pageOptions.pageNumbers && (
            <ToolbarToggle
              pressed={pageOptions.pageNumbers}
              icon={<Hash />}
              tooltip={t`Toggle Page Numbers`}
              onPressedChange={(pressed) => setValue("metadata.page.options.pageNumbers", pressed)}
            />
          )}

          <Separator orientation={orientation} />
          <ToolbarButton
            icon={<LinkSimple />}
            tooltip={t`Copy Link to Resume`}
            disabled={!isPublic}
            side={side}
            size={size}
            onClick={onCopy}
          />
        </>
      )}
      <Separator orientation={orientation} />
      <ToolbarButton
        size={size}
        icon={loading ? <CircleNotch className="animate-spin" /> : <FilePdf />}
        tooltip={t`Download PDF`}
        disabled={loading}
        side={side}
        onClick={onPrint}
      />
    </>
  );

  return (
    <motion.div className="fixed bottom-0 flex w-full justify-center py-3 text-center lg:left-[20%]">
      <div className="inline-flex h-12 flex-row items-center rounded-full border border-primary bg-background p-2 text-primary">
        {toolbarContent}
      </div>
    </motion.div>
  );
};

export default Toolbar;
