import { useBreakpoint } from "@resume-plus/hooks";
import React, { ComponentType, createContext, ReactNode, useContext } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./drawer";

type ResponsiveContextType = {
  responsiveEnabled: boolean;
};

const ResponsiveContext = createContext<ResponsiveContextType>({ responsiveEnabled: true });

export const useResponsiveContext = () => useContext(ResponsiveContext);

type ResponsiveProps = {
  children: ReactNode;
  className?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  asChild?: boolean;
};

const withResponsiveComponent = <P extends ResponsiveProps>(
  DesktopComponent: ComponentType<P>,
  MobileComponent: ComponentType<P>,
) => {
  return (props: P) => {
    const { responsiveEnabled } = useResponsiveContext();
    const { isDesktop } = useBreakpoint();
    const isMobile = !isDesktop;

    const Component = responsiveEnabled
      ? // eslint-disable-next-line unicorn/no-nested-ternary
        isMobile
        ? MobileComponent
        : DesktopComponent
      : DesktopComponent;

    return <Component {...props} />;
  };
};

const ResponsiveDialogProvider: React.FC<{ responsiveEnabled: boolean; children: ReactNode }> = ({
  responsiveEnabled,
  children,
}) => {
  return (
    <ResponsiveContext.Provider value={{ responsiveEnabled }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

const ResponsiveDialog: React.FC<ResponsiveProps & { responsiveEnabled?: boolean }> = ({
  responsiveEnabled = true,
  ...rest
}) => {
  const { isDesktop } = useBreakpoint();
  const Component = responsiveEnabled ? (isDesktop ? Dialog : Drawer) : Dialog;

  return (
    <ResponsiveDialogProvider responsiveEnabled={responsiveEnabled}>
      <Component {...rest} />
    </ResponsiveDialogProvider>
  );
};

const ResponsiveDialogContent = withResponsiveComponent(DialogContent, DrawerContent);
const ResponsiveDialogHeader = withResponsiveComponent(DialogHeader, DrawerHeader);
const ResponsiveDialogFooter = withResponsiveComponent(DialogFooter, DrawerFooter);
const ResponsiveDialogTitle = withResponsiveComponent(DialogTitle, DrawerTitle);
const ResponsiveDialogDescription = withResponsiveComponent(DialogDescription, DrawerDescription);
const ResponsiveDialogTrigger = withResponsiveComponent(DialogTrigger, DrawerTrigger);
const ResponsiveDialogClose = withResponsiveComponent(DialogClose, DrawerClose);

export {
  ResponsiveDialog,
  ResponsiveDialogClose,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
  ResponsiveDialogTrigger,
};
